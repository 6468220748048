<template>
  <div style="min-height: 100%">
    <van-cell-group title="请填写个人基本信息">
      <div slot="title" style="display: flex;justify-content: space-between">
        <div>
          个人基本信息
        </div>
        <div>
          <a href="javascript:void(0);" @click="ToList()">注册列表</a>
        </div>
      </div>
    </van-cell-group>
    <van-cell title="姓名" :value="userInfo.name"/>
    <van-cell title="性别" :value="formatterSex(userInfo.sexId)"/>
    <van-cell title="手机号" :value="userInfo.tel"/>
    <van-cell title="身份证号" :value="userInfo.idCode"/>
    <van-cell title="所属企业" :value="centerData.centerName"/>
    <van-cell title="部门" :value="deptName(userInfo.maindeptId)"/>
    <van-cell title="个人照片" center>
      <template>
        <van-image :src="userInfo.photo"
                   fit="cover"
                   @click="showPng(userInfo.photo)"
                   width="100px"
                   height="100px">
        </van-image>
      </template>
    </van-cell>
    <van-cell title="附件证明" center>
      <template>
        <div v-if="userInfo.photo2">
          <van-image :src="userInfo.photo2"
                     fit="cover"
                     @click="showPng(userInfo.photo2)"
                     width="100px"
                     height="100px">
          </van-image>
        </div>
        <div v-if="!userInfo.photo2">
          无附件
        </div>
      </template>
    </van-cell>
    <van-cell title="审批备注" :value="userInfo.idCode"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ImagePreview} from "vant";

export default {
  name: "RegisterDetails",
  //组件引入
  components: {},
  //父子组件传值
  props: {},
  //计算属性，可以缓存，避免每次计算都要重新计算
  computed: {
    ...mapGetters({
      path: 'getPath',
      path2: 'getPath2',
      deptName: 'getDeptName',
      centerData: 'getCenterData'
    }),
    /**
     * 格式化图片
     */
    formatterImage() {
      return () => {
        console.log(this.userInfo, 'userInfo');
        return this.path + this.userInfo.photo
      }
    },
    /**
     * 格式化性别
     * @returns {function(*=)}
     */
    formatterSex() {
      return type => {
        let data = {
          0: '男',
          1: '女'
        }
        return data[parseInt(type)] || '未知'
      }
    }
  },
  //filters 过滤器
  filters: {},
  //数据
  data() {
    return {
      userInfo: {}
    }
  },
  //在模板渲染成html前调用,即通常初始化某些属性值,然后再渲染成视图。
  created() {
  },
  //在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
  mounted() {
    if (this.$route.query) {
      let queryData = this.$route.query
      let data = {
        ...queryData,
        photo: queryData.photo ? this.path + '/' + queryData.photo : null,
        photo2: queryData.id ? this.path2 + '/' + queryData.id+'.jpg' : null
      }
      this.userInfo = data
      console.log(this.userInfo, 'userInfo');
    }
  },
  //方法 处理逻辑
  methods: {
    showPng(url) {
      console.log(url, 'url');
      ImagePreview({
        images: [
          url
        ],
        closeable: true,
      });
    },
    ToList() {
      this.$router.go(-1)
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas"); // 创建一个canvas
      canvas.width = img.width; // 设置对应的宽高
      canvas.height = img.height;
      var ctx = canvas.getContext("2d"); // 二维绘图环境
      ctx.drawImage(img, 0, 0, img.width, img.height); // 将图片画在画布上
      var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase(); // 获取到图片的格式
      var dataURL = canvas.toDataURL("image/" + ext); // 得到base64 编码的 dataURL
      console.log(dataURL)
      return dataURL;
    }
  }
}
</script>

<style scoped>

</style>
